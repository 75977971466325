<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Technicians/List</h4>
          <div>
            <router-link :to="can('technician-create') && enableTechnicianCreate ? {name:'appTechnicianCreate'} : '#'">
              <span :title="can('technician-create') && enableTechnicianCreate ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>

        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect
                    v-model="selectedPagination"
                    class=""
                    :options="paginationList"
                    :close-on-select="true"
                    label="name"
                    track-by="value"
                    :show-labels="false"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Role</label>
                <VueMultiselect
                    v-model="selectedRole"
                    class=""
                    :options="roleList"
                    :close-on-select="true"
                    label="name"
                    track-by="value"
                    :show-labels="false"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>State</label>
                <VueMultiselect
                    v-model="selectedState"
                    class=""
                    :options="stateList"
                    :close-on-select="true"
                    label="name"
                    track-by="value"
                    :show-labels="false"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect
                    v-model="selectedStatus"
                    class=""
                    :options="statusList"
                    :close-on-select="true"
                    placeholder="Select status"
                    label="name"
                    track-by="value"
                    :show-labels="false"
                />
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                <input
                    v-model="getTechniciansParams.where_has_user_query"
                    class="form-control search-product-input-element"
                    type="text"
                    placeholder="Search a admin by name or email or phone"
                />
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset"
                        class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyTechniciansFilter(null)">
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" style="width: 5%">SL</th>
                    <th class="position-relative" style="width: 25%">NAME</th>
                    <th class="position-relative" style="width: 15%">Employment Type</th>
                    <th class="position-relative" style="width: 10%">PHONE</th>
                    <th class="position-relative" style="width: 15%">EMAIL</th>
                    <th class="position-relative" style="width: 25%">ADDRESS</th>
                    <th class="position-relative" style="width: 10%">STATUS</th>
                    <th class="position-relative text-right" style="width: 5%">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(technician, index) in technicians" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td class="small-badge">
                      <span v-if="technician.user">
                        {{ technician.user.full_name }}
                      </span>
                      <span class="text-warning"
                            v-if="!authFranchisee.id && technician.franchiseeTechnician">
                        &nbsp;(Franchisee)
                      </span>
                    </td>
                    <td>
                      <span class="badge badge-light-primary" :class="technician.employee.type === 'Contractual' || technician.employee.type === 'Partner' ? 'badge-light-warning' : 'badge-light-primary' ">{{ technician.employee.type }}</span>
                    </td>
                    <td>{{ technician.user && technician.user.phone_number ? technician.user.phone_number : '' }}</td>
                    <td>{{ technician.user && technician.user.email ? technician.user.email : '' }}</td>
                    <td>
                        <span v-if="technician.employee && technician.employee.address">
                          {{
                            `${technician.employee.address.street}, ${technician.employee.address.suburb}, ${technician.employee.address.state.toUpperCase()},  ${technician.employee.address.post_code}, ${technician.employee.address.country}`
                          }}
                        </span>
                    </td>
                    <td>
                      <button class="btn"
                              :disabled="!can('technician-update')"
                              :title="can('technician-update') ? `${technician.status} | Click to update` : `${technician.status} | ${unauthorized}`"
                              data-toggle="modal"
                              data-target="#technicianStatusUpdateAlertModal"
                              @click="this.modelForUpdating = {modelId: technician.id, existingData: {status: technician.status}}">
                        <span class="badge"
                              :class="technician.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                          {{ technician.status }}
                        </span>
                      </button>
                    </td>

                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <router-link
                            class="text-decoration-none view-edit-delete-icon"
                            style="height: 18px"
                            :to="{name: 'appTechnicianView', params: { id: technician.id } }"><i class="bx bx-show"></i>
                        </router-link>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                          <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <router-link class="dropdown-item"
                                         :to="can('technician-update') ? {name: 'appTechnicianScheduleEdit', params: { id: technician.id }} : '#'">
                              Update Schedules
                            </router-link>
                            <button :disabled="!can('technician-update')"
                                    class="dropdown-item"
                                    @click="openRestPasswordModal(technician)"
                                   >Reset Password
                            </button>
                          </div>
                        </div>

                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination
                    position="right"
                    :pagination-data="paginateLinks"
                    @getClickedPage="applyTechniciansFilter"/>
              </div>
            </div>
          </div>
        </div>
        <div class="" data-toggle="modal" data-target="#restPasswordModal"></div>
        <TechnicianStatusUpdateAlertModal :model-for-updating="modelForUpdating" model-name="technician"
                                          modal-name="technicianStatusUpdateAlertModal"
                                          @confirmModelUpdating="technicianStatusUpdating"/>
        <ResetPasswordModal :userEmailOrPhone="resetPasswordUserEmailOrPhone"/>

      </section>
    </template>
  </AppLayout>
</template>

<script>
// component
import AppLayout from "@/layouts/backEnd/AppLayout";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import TechnicianStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import ResetPasswordModal from '@/components/backEnd/modal/ResetPasswordModal';

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


// core package
import {mapActions, mapGetters} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';


export default {
  name: "TechnicianList",
  mixins: [ShowToastMessage, Authorization, Loader],
  components: {
    AppLayout,
    ListPagination,
    VueMultiselect,
    TechnicianStatusUpdateAlertModal,
    ResetPasswordModal
  },
  data() {
    return {
      resetPasswordUserEmailOrPhone:"",
      unauthorized: 'You are unauthorized to perform this action.',
      enableTechnicianCreate: true,
      selectedPagination: {
        value: "",
        name: "Default",
      },
      selectedRole: {
        value: "",
        name: "Any",
      },
      selectedState: {
        value: "",
        name: "Any",
      },
      selectedStatus: {
        value: "",
        name: "Any",
      },
      getTechniciansParams: {
        where_has_user_query: "",
        where_has_employee_address_state: "",
        where_has_user_role_id: "",
        where_has_franchisee_Technician_franchisee_id: "",
        status: "",
        with_relation: ["user", "user.role", "employee", "employee.address", "franchiseeTechnician"],
        order_by_id: "DESC",
        paginate: 1,
        pagination: "",
        page: "",
      },
      getSettingsParams: {
        type: ["default"],
        key: ["pagination", "default_state"],
      },
      getRolesParams: {
        type: 2,
      },
      getFranchiseeParams: {
        with_relation: ["franchiseeTechnicians"],
      },
      modelForUpdating: {
        ModelId: '',
        existingData: {},
      },
    };
  },
  watch: {
    async selectedPagination(selectedPagination) {
      this.getTechniciansParams.pagination = selectedPagination.value;
    },
    async selectedState(selectedState) {
      this.getTechniciansParams.where_has_employee_address_state =
          selectedState.value;
    },
    async selectedRole(selectedRole) {
      this.getTechniciansParams.where_has_user_role_id = selectedRole.value;
    },
    async selectedStatus(selectedStatus) {
      this.getTechniciansParams.status = selectedStatus.value;
    },
  },
  computed: {
    ...mapGetters({
      authFranchisee: "appAuthLogin/authFranchisee",
      franchisee: 'appFranchisees/franchisee',
      technicians: "appTechnicians/technicians",
      paginateLinks: "appTechnicians/paginateLinks",
    }),
    statusList() {
      return [
        {
          value: "",
          name: "Any",
        },
        {
          value: 0,
          name: "Inactive",
        },
        {
          value: 1,
          name: "Active",
        },
      ];
    },
    paginationList() {
      return [
        {
          value:
          this.$store.getters["appSettings/settingDefaultPagination"].value,
          name: "Default",
        },
        {
          value: 25,
          name: "25 Entries",
        },
        {
          value: 50,
          name: "50 Entries",
        },
        {
          value: 100,
          name: "100 Entries",
        },
      ];
    },
    stateList() {
      return [
        {
          value: "",
          name: "Any",
        },
        ...this.$store.getters["appSettings/settingDefaultState"].value,
      ];
    },
    roleList() {
      let activeRoles = this.$store.getters["appRoles/roles"].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {
          value: roleId,
          name: roleName,
        };
      });

      return [
        {
          value: "",
          name: "None",
        },
        ...activeRoles,
      ];
    },
  },
  methods: {
    ...mapActions({
      resetRoles: "appRoles/resetRoles",
      resetTechnicians: "appTechnicians/resetTechnicians",

      getSettings: "appSettings/getSettings",
      getRoles: "appRoles/getRoles",
      getTechnicians: "appTechnicians/getTechnicians",
      getFranchisee: "appFranchisees/getFranchisee",

      putTechnicianOnList: "appTechnicians/putTechnicianOnList"
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getTechnicianTypeRoleList() {
      await this.getRoles(this.getRolesParams);
    },

    async getTechnicianList() {
      await this.getTechnicians(this.getTechniciansParams).then(
          async (response) => {
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
          }
      );
    },

    async applyTechniciansFilter(pageNumber) {
      this.loader(true);
      this.getTechniciansParams.page = pageNumber;
      await this.getTechnicianList();
      this.loader(false);
    },

    async technicianStatusUpdating(TechnicianStatusUpdating) {
      await this.technicianStatusUpdatingOnList(TechnicianStatusUpdating);
    },

    async technicianStatusUpdatingOnList(TechnicianStatusUpdating) {
      let dataObj = {
        id: TechnicianStatusUpdating.modelForUpdating.modelId,
        data: {
          status: TechnicianStatusUpdating.modelForUpdating.existingData.status === "Active" ? 0 : 1,
        }
      }

      this.putTechnicianOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Technician status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },


    async getSingleFranchisee() {
      let paramObj = {
        id: this.authFranchisee.id,
        params: this.getFranchiseeParams,
      };

      await this.getFranchisee(paramObj);
    },
    openRestPasswordModal(technician){
      this.resetPasswordUserEmailOrPhone = "";
      if (technician?.user?.email) {
        this.resetPasswordUserEmailOrPhone = technician.user.email;
        document.querySelector('[data-target="#restPasswordModal"]').click();
        return;
      }
      if (technician?.user?.phone_number) {
        this.resetPasswordUserEmailOrPhone = technician.user.phone_number;
        document.querySelector('[data-target="#restPasswordModal"]').click();
        return;
      }
    },
  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();

    if (this.authFranchisee.id) {
      this.getTechniciansParams.where_has_franchisee_technician_franchisee_id = this.authFranchisee.id;

      await this.getSingleFranchisee();

      let franchiseeTechnicianLimit = this.franchisee.technician_limit ? this.franchisee.technician_limit : 0;
      let TotalFranchiseeTechnician = this.franchisee.franchiseeTechnicians ? this.franchisee.franchiseeTechnicians.length : 0;

      if (TotalFranchiseeTechnician >= franchiseeTechnicianLimit) {
        this.enableTechnicianCreate = false;
      }
    }

    await this.getTechnicianTypeRoleList();
    await this.getTechnicianList();
    this.loader(false);
  },

  async beforeMount() {
    await this.resetRoles();
    await this.resetTechnicians();
  },
};
</script>

<style scoped>
.view-edit-delete-icon {
  color: #b3c0ce;
  transition: all 0.2s;
}

.view-edit-delete-icon:hover {
  color: #5a8dee;
}

.badge {
  display: inline-block;
  padding: 4px 8px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
